import * as React from 'react'
import * as AlertDialogPrimitive from '@radix-ui/react-alert-dialog'
import { classNames, Button } from '@upper/ui'
import TextLinkButton from './text-link-button'

function Root({
  children,
  ...props
}: AlertDialogPrimitive.AlertDialogProps & {
  children: React.ReactNode
}) {
  return (
    <AlertDialogPrimitive.Root {...props}>
      <AlertDialogPrimitive.Overlay className="fixed inset-0 bg-gray-darkest/75 state-open:animate-fade-in state-closed:animate-fade-out" />
      {children}
    </AlertDialogPrimitive.Root>
  )
}

const Content = React.forwardRef<
  HTMLDivElement,
  AlertDialogPrimitive.AlertDialogContentProps
>(({ className, ...rest }, forwardedRef) => (
  <AlertDialogPrimitive.Content
    {...rest}
    ref={forwardedRef}
    className={classNames(
      'fixed w-[90vw] max-w-[400px] max-h-[85vh] px-6 pt-12 pb-6 -translate-x-1/2 -translate-y-1/2 rounded-lg bg-white shadow-xl top-1/2 left-1/2 overflow-y-auto text-center state-open:animate-scale-in sm:px-10 sm:pt-20 sm:pb-10',
      className
    )}
  />
))

function Title({
  className,
  ...rest
}: AlertDialogPrimitive.AlertDialogTitleProps) {
  return (
    <AlertDialogPrimitive.Title
      {...rest}
      className={classNames(
        'text-2xl font-bold leading-tight text-blue',
        className
      )}
    />
  )
}

function Description(props: AlertDialogPrimitive.AlertDialogDescriptionProps) {
  return (
    <AlertDialogPrimitive.Description
      {...props}
      className="mt-4 font-medium text-gray-dark"
    />
  )
}

function Controls({ children }: { children: React.ReactNode }) {
  return <div className="mt-8 space-y-4 sm:mt-10">{children}</div>
}

function Action({
  children,
  ...rest
}: { children: React.ReactNode } & React.ComponentPropsWithoutRef<'button'>) {
  return (
    <AlertDialogPrimitive.AlertDialogAction asChild>
      <Button fullWidth {...rest}>
        {children}
      </Button>
    </AlertDialogPrimitive.AlertDialogAction>
  )
}

function Cancel({ children }: { children: React.ReactNode }) {
  return (
    <AlertDialogPrimitive.AlertDialogCancel asChild>
      <TextLinkButton variant="gray" fullWidth>
        {children}
      </TextLinkButton>
    </AlertDialogPrimitive.AlertDialogCancel>
  )
}

export const AlertDialog = Root
export const AlertDialogTrigger = AlertDialogPrimitive.Trigger
export const AlertDialogContent = Content
export const AlertDialogTitle = Title
export const AlertDialogDescription = Description
export const AlertDialogControls = Controls
export const AlertDialogAction = Action
export const AlertDialogCancel = Cancel
