import {
  EngagementCardFragment,
  EngagementStatus,
  JobCardFragment,
} from '@upper/graphql/freelancer'
import { LabelProps } from '@upper/ui'

export const ENGAGEMENT_REJECTED_STATUSES = [
  EngagementStatus.RejectedClient,
  EngagementStatus.RejectedFellow,
  EngagementStatus.RejectedFreelancer,
  EngagementStatus.RejectedUpper,
]

export const ENGAGEMENT_CANCELLED_STATUSES = [
  EngagementStatus.CancelledClient,
  EngagementStatus.CancelledFreelancer,
  EngagementStatus.CancelledUpper,
]

export const ENGAGEMENT_STATUS_LABELS: Partial<
  Record<EngagementStatus, string>
> = {
  [EngagementStatus.Invited]: 'Invited',
  [EngagementStatus.Applied]: 'Applied',
  [EngagementStatus.InterviewUpperPersonal]: 'Applied',
  [EngagementStatus.Challenge]: 'Applied',
  [EngagementStatus.InterviewUpperTechnical]: 'Applied',
  [EngagementStatus.UpperOnboarding]: 'Applied',
  [EngagementStatus.ExpertReview]: 'Matching',
  [EngagementStatus.Matching]: 'Matching',
  [EngagementStatus.Prepare]: 'Matching',
  [EngagementStatus.CreateProfile]: 'Matching',
  [EngagementStatus.ReadyToPropose]: 'Matching',
  [EngagementStatus.ProposedFellow]: 'Proposed',
  [EngagementStatus.InterviewFellow]: 'Interview',
  [EngagementStatus.ProposedClient]: 'Proposed',
  [EngagementStatus.InterviewClient]: 'Interview',
  [EngagementStatus.Onboarding]: 'Onboarding',
  [EngagementStatus.Hired]: 'Hired',
  [EngagementStatus.RejectedClient]: 'No match',
  [EngagementStatus.RejectedFellow]: 'No match',
  [EngagementStatus.RejectedFreelancer]: 'Rejected',
  [EngagementStatus.RejectedUpper]: 'No match',
  [EngagementStatus.Ended]: 'Ended',
  [EngagementStatus.CancelledClient]: 'Ended',
  [EngagementStatus.CancelledFreelancer]: 'Ended',
  [EngagementStatus.CancelledUpper]: 'Ended',
  [EngagementStatus.ClientAssignment]: 'Assignment',
}

export const ENGAGEMENT_STATUS_LABEL_COLORS: Partial<
  Record<EngagementStatus, LabelProps['color']>
> = {
  [EngagementStatus.Matching]: 'orange',
  [EngagementStatus.Prepare]: 'orange',
  [EngagementStatus.ReadyToPropose]: 'orange',
  [EngagementStatus.ProposedFellow]: 'orange',
  [EngagementStatus.InterviewFellow]: 'orange',
  [EngagementStatus.ProposedClient]: 'orange',
  [EngagementStatus.InterviewClient]: 'orange',
  [EngagementStatus.Onboarding]: 'orange',
  [EngagementStatus.Hired]: 'green',
  [EngagementStatus.RejectedClient]: 'red',
  [EngagementStatus.RejectedFellow]: 'red',
  [EngagementStatus.RejectedFreelancer]: 'red',
  [EngagementStatus.RejectedUpper]: 'red',
  [EngagementStatus.Ended]: 'gray',
  [EngagementStatus.CancelledClient]: 'gray',
  [EngagementStatus.CancelledFreelancer]: 'gray',
  [EngagementStatus.CancelledUpper]: 'gray',
  [EngagementStatus.ClientAssignment]: 'orange',
}

export function transformEngagementsForJobsList(
  engagements: EngagementCardFragment[]
) {
  return engagements.map(
    (
      engagement
    ): Partial<JobCardFragment> & {
      engagementId?: string
      engagementStatus?: EngagementStatus | null
      engagementRejectionReasons?: string[] | null
      engagementExtraExplanation?: string | null
    } => ({
      ...engagement.job,
      engagementId: engagement.id!,
      engagementStatus: engagement.latestRevision?.status,
      engagementExtraExplanation:
        engagement.latestRevision?.statusExtraExplanation,
      engagementRejectionReasons: engagement.latestRevision?.rejectionReasons,
    })
  )
}
