import { classNames } from '@upper/ui'
import * as React from 'react'
import type {
  PolymorphicPropsWithoutRef,
  PolymorphicPropsWithRef
} from 'react-polymorphic-types'

const TextLinkButtonDefaultElement = 'button'

type TextLinkButtonOwnProps = {
  variant?: 'blue' | 'gray'
  fullWidth?: boolean
}

export type TextLinkButtonProps<
  T extends React.ElementType = typeof TextLinkButtonDefaultElement
> = PolymorphicPropsWithRef<TextLinkButtonOwnProps, T>

const TextLinkButtonButton = React.forwardRef(function <
  T extends React.ElementType = typeof TextLinkButtonDefaultElement
>(
  {
    as,
    variant = 'blue',
    className,
    children,
    fullWidth,
    ...restProps
  }: TextLinkButtonProps<T>,
  ref: React.ForwardedRef<Element>
) {
  const Element: React.ElementType = as || TextLinkButtonDefaultElement

  return (
    <Element
      ref={ref}
      className={classNames(
        'inline-flex items-center px-6 py-3 underline font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-light hover:no-underline',
        variant === 'blue' ? 'text-blue' : 'text-gray',
        fullWidth && 'w-full justify-center',
        className
      )}
      {...restProps}
    >
      {children}
    </Element>
  )
})

export default TextLinkButtonButton
